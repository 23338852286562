<template>
  <BaseDialog
    :propDialog="dialog"
    :title="title"
    :validity="valid"
    :loader="loading"
    @confirm="confirmUpdate"
    @closeDialog="setDialog"
  >
    <v-container class="px-0">
      <div
        v-if="container === 'kitchen' && (complete || !found)"
        class="title primary--text mb-3"
      >
        {{ request.tableName }} - {{ request.customerName }}
      </div>
      <div v-if="complete && request.note" class="d-flex text-subtitle-2 mb-3">
        <div class="mr-2 flex-shrink-0">{{ $t('common.note') }}:</div>
        <div class="font-italic">{{ request.note }}</div>
      </div>
      <v-form ref="form" v-model="valid" @submit.prevent>
        <OrderDropdown
          v-if="!complete"
          v-show="found"
          param="serving"
          :orderId.sync="cloneRequest.orderId"
          @setFound="onSetFound"
        />
        <BaseTextInput
          v-if="!complete && !request.isClosedOrder"
          label="kitchen-request.note"
          v-model="cloneRequest.note"
          :character-limit="99"
        />
        <BaseListToggle
          :item="cloneRequest"
          :canToggle="!request.isClosedOrder"
          :canEdit="!request.isClosedOrder"
          quantityKey="quantity"
          nameKey="viName"
          valueKey="price"
          @increase="onIncrease"
          @decrease="onDecrease"
          @updateValue="onUpdateValue"
        />
      </v-form>
    </v-container>
  </BaseDialog>
</template>

<script>
import { displayErrorMessage, updateRequest, getNewVal } from '@/utils'
import cloneDeep from 'lodash/cloneDeep'
import OrderDropdown from '../orders/OrderDropdown'

export default {
  name: 'UpdateRequest',

  components: {
    OrderDropdown
  },

  props: {
    title: String,
    request: Object,
    dialog: Boolean,
    complete: Boolean,
    container: {
      validator: function(value) {
        return ['order-dialog', 'kitchen', 'print'].includes(value)
      },
      default: 'kitchen'
    }
  },

  data: () => ({
    loading: false,
    cloneRequest: {},
    valid: true,
    found: true
  }),

  computed: {
    quantity() {
      return this.cloneRequest.quantity
    },

    inKilogram() {
      return this.cloneRequest.inKilogram
    }
  },

  watch: {
    dialog(val) {
      if (val === true) {
        this.cloneRequest = cloneDeep(this.request)
      }
    },

    quantity() {
      if (this.quantity === 0) {
        this.$emit('update:dialog', false)
      }
    }
  },

  methods: {
    setDialog(val) {
      this.$emit('update:dialog', val)
    },

    onIncrease() {
      this.cloneRequest.quantity = getNewVal(
        this.quantity,
        true,
        this.inKilogram
      )
    },

    onDecrease() {
      this.cloneRequest.quantity = getNewVal(
        this.quantity,
        false,
        this.inKilogram
      )
    },

    async confirmUpdate() {
      this.loading = true

      let type
      if (this.complete) {
        this.cloneRequest.status = 'ready'
        type = 'completeRequest'
      } else {
        type = 'editRequest'
      }
      try {
        await updateRequest(this.cloneRequest, type)
        this.setDialog(false)
      } catch (err) {
        displayErrorMessage(err)
      } finally {
        this.loading = false
      }
    },

    onUpdateValue(payload) {
      const { item, key, value } = payload
      const updatedCloneRequest = { ...item }
      updatedCloneRequest[key] = value
      this.cloneRequest = updatedCloneRequest
    },

    onSetFound(val) {
      this.found = val
    }
  }
}
</script>

<style scoped></style>
