<template>
  <div>
    <BaseDropdown
      label="order.order-name"
      no-data="order.no-order"
      v-model="newOrderId"
      :items="orders"
      :item-text="item => `${item.tableName} - ${item.customerName}`"
      item-value="_id"
      required
      :disabled="!isFound"
      @change="$emit('update:orderId', newOrderId)"
    />
  </div>
</template>

<script>
import OrderService from '@/services/modules/OrderService'
import { displayErrorMessage } from '@/utils'

export default {
  name: 'OrderDropdown',

  props: {
    param: String,
    orderId: String
  },

  data: () => ({
    orders: [],
    newOrderId: ''
  }),

  computed: {
    isFound() {
      if (this.orderId) {
        return this.orders.some(x => x._id === this.orderId)
      } else {
        return true
      }
    }
  },

  mounted() {
    OrderService.getList(this.param)
      .then(res => (this.orders = res.data.docs))
      .catch(err => displayErrorMessage(err))
  },

  watch: {
    orderId: {
      immediate: true,
      handler: function(val) {
        this.newOrderId = val
      }
    },

    isFound: {
      immediate: true,
      handler: function(val) {
        this.$emit('setFound', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
